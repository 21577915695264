const burgerMenu = document.querySelector(".hamburger");
const overlay = document.querySelector(".menu");

burgerMenu.addEventListener("click", function () {
  this.classList.toggle("close");
  overlay.classList.toggle("overlay");
});

// close menu if click is not .menu.overlay
document.addEventListener("click", function (e) {
  if (
    e.target !== overlay &&
    !overlay.contains(e.target) &&
    !burgerMenu.contains(e.target)
  ) {
    burgerMenu.classList.remove("close");
    overlay.classList.remove("overlay");
  }
});
